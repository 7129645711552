import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Grid} from '../components/responsive/'
import H1 from '../components/h1'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import Session from '../components/sessionPlan'
import SessionTop from '../components/sessionTop'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata title='セッション'/>
      <Wrap>
      <Header active='sessions'/>
      <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em'>
      <H1 fluid={data.session.childImageSharp.fluid} info='Drupal Camp DEN Japan Vol.1の様子'>
      セッション
      </H1>

      <SessionTop title='Introduction' place='コワーキング'/>
      <Session session={{
        field_session_start : '10:00',
        field_session_end : '10:05',
        field_session_name : '開会のご挨拶',
        field_session_user : '実行委員長　小薗井 康志',
        field_session_movie_url : '',
        field_session_presentation_url : ''
      }}/>
      <Session session={{
        field_session_start : '10:05',
        field_session_end : '10:10',
        field_session_name : '「Driesからのメッセージ」動画の発表',
        field_session_user : '西尾 正博様',
        field_session_movie_url : '',
        field_session_presentation_url : ''
      }}/>

      <SessionTop title='Keynote speech' place='コワーキング'/>
      <Session session={{
        field_session_start : '10:10',
        field_session_end : '11:00',
        field_session_name : 'キーノートスピーチ：The new CMS paradox: Coming conflicts in content management',
        field_session_user : 'Preston So（プレストン・ソー）様 Principal Product Manager, GATSBY',
        field_session_movie_url : '',
        field_session_presentation_url : 'https://den-japan.org/sites/default/files/den2019presentation/The%20new%20CMS%20paradox_%20Coming%20conflicts%20in%20content%20management%20%28DEN%20Camp%20Nagoya%202019%20keynote%29.pdf'
      }}/>

      <SessionTop title='パネルディスカッション' place='コワーキング'/>
      <Session session={{
        field_session_start : '11:10',
        field_session_end : '11:50',
        field_session_name : '【API、デカップルドをテーマ】にしてのグループトーク　米国・インド・日本 ３か国先端エンジニアによる最新開発手法についてディスカッション',
        field_session_user : 'Preston So（プレストン・ソー）様・Gautam Das（ガウタム・ダス）様・岸 俊兵様　コーディネーター　萩原 高行様',
        field_session_movie_url : '',
        field_session_presentation_url : ''
      }}/>

      <Row>
        <Grid pc='6' sp='6'>
          <SessionTop title='Track A' place='コワーキング'/>
        </Grid>
        <Grid pc='6' sp='6'>
          <SessionTop title='Track B' place='A会議室'/>
        </Grid>
      </Row>
      <Row>
      {data.camp2019.rest.sessions.map((session, key) => {
        return<Grid key={key} pc={session.field_session_track==='24' ? '12' : '6'}
          sp={session.field_session_track==='24' ? '12' : '6'}>
            <Session session={session}/>
          </Grid>
      })}
      </Row>

      <SessionTop title='アクイア認定試験' place='B会議室C会議室'/>
      <Session session={{
        field_session_start : '13:00',
        field_session_end : '14:15',
        field_session_name : 'アクイア認定サイトビルダー – Drupal 8',
        field_session_user : 'アクイアジャパン合同会社',
        field_session_movie_url : '',
        field_session_presentation_url : ''
      }}
      />

      <Session session={{
        field_session_start : '14:30',
        field_session_end : '15:45',
        field_session_name : 'アクイア認定サイトビルダー – Drupal 8',
        field_session_user : 'アクイアジャパン合同会社',
        field_session_movie_url : '',
        field_session_presentation_url : ''
      }}/>

      <ContainerFooter/>
      </Container>
      <Footer/>
      </Wrap>
      </div>
    )

export const query = graphql`
  query {
    session:file(relativePath: { eq: "session.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    camp2019{
      rest{
        sessions {
          field_session_user
          field_session_name
          field_session_end
          field_session_start
          field_session_track
          field_session_movie_url
          field_session_presentation_url
        }
      }
    }
  }
`
